import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { collection, collectionGroup, doc, Firestore, getDocs, query, runTransaction, serverTimestamp, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Attendee } from 'src/@types/attendee';
import { DB, FUNCTIONS } from 'src/contexts/FirebaseContext';

interface RefundRequest {
  amount: number;
  ticketid: string;
  eventid: string;
  reservationid: string;
  pi: string;
  userid: string;
}

interface RefundResponse {
  id: string;
  object: 'refund';
  status: string;
  // Add other Stripe refund response fields as needed
}

interface UpdateReservationUserInfo {
  reservationId: string;
  firstName: string;
  lastName: string;
  email: string;
  ticketId: string;  // Add this to know which ticket to update
  userId: string; 
}



export const ReservationsApi = createApi({
    reducerPath: 'ReservationsApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Attendees', 'Tickets', 'Reservations'],
  endpoints: (builder) => ({
    getAttendees: builder.query<Attendee[], { eventId: string; status: string; outputFormat: string }>({
      async queryFn(args) {
        try {
          const fetchReservationsCallable = httpsCallable<
            { eventId: string; status: string; outputFormat: string },
            { attendees: Attendee[] }
          >(FUNCTIONS, 'reservations-fetchReservationsCallable');
          
          const result = await fetchReservationsCallable(args);
          return { data: result.data.attendees };
        } catch (error: any) {
          console.error(error);
          return { error: error.message };
        }
      },
      providesTags: ['Attendees'],
    }),
    getConfirmedTicketIds: builder.query<string[], string>({
      async queryFn(eventId) {
        try {
          const ticketsRef = collectionGroup(DB, 'tickets');
          const q = query(
            ticketsRef, 
            where('eventid', '==', eventId),
            where('status', '==', 'confirmed')
          );
          const querySnapshot = await getDocs(q);
          const ticketIds: string[] = [];
          querySnapshot.forEach((doc) => {
            ticketIds.push(doc.id);
          });
          return { data: ticketIds };
        } catch (error: any) {
          return { error: error.message };
        }
      },
    }),
    updateReservationUserInfo: builder.mutation<void, UpdateReservationUserInfo>({
      async queryFn({ reservationId, firstName, lastName, email, ticketId, userId }) {
        try {
          await runTransaction(DB, async (transaction) => {
            // Reference to the main reservation document
            const reservationRef = doc(DB, "reservations", reservationId);
            
            // Get the current reservation data to compare changes
            const reservationDoc = await transaction.get(reservationRef);
            const currentData = reservationDoc.data();
            
            // Reference to the ticket document
            const ticketRef = doc(
              collection(reservationRef, "tickets"), 
              ticketId
            );
    
            // Prepare the user info update data
            const userInfos = {
              firstName,
              lastName,
              name: `${firstName} ${lastName}`,
              email,
            };
    
            // Create a changes document to record the modification
            const changesCollection = collection(reservationRef, "changes");
            const changeDoc = doc(changesCollection);
    
            // Record what changed
            const changes = {
              previousValues: {
                firstName: currentData?.userinfos?.firstName,
                lastName: currentData?.userinfos?.lastName,
                email: currentData?.client?.email,
                name: currentData?.client?.name,
              },
              newValues: {
                firstName,
                lastName,
                email,
                name: `${firstName} ${lastName}`,
              },
              modifiedAt: serverTimestamp(),
              modifiedBy: userId,
              type: 'user_info_update',
              ticketId,
            };
    
            // Update the main reservation document
            transaction.update(reservationRef, {
              "client.email": email,
              "client.name": `${firstName} ${lastName}`,
              userinfos: userInfos,
              lastModifiedAt: serverTimestamp(),
              lastModifiedBy: userId,
            });
    
            // Update the ticket document
            transaction.update(ticketRef, {
              userinfos: userInfos,
              lastModifiedAt: serverTimestamp(),
              lastModifiedBy: userId,
            });
    
            // Add the change record
            transaction.set(changeDoc, changes);
          });
    
          return { data: undefined };
        } catch (error: any) {
          console.error("Error updating reservation:", error);
          return {
            error: {
              status: error?.code || 'UNKNOWN_ERROR',
              data: error?.message || 'An unknown error occurred',
            },
          };
        }
      },
      invalidatesTags: ['Reservations'],
    }),
    refundTicket: builder.mutation<RefundResponse, RefundRequest>({
      async queryFn(refundData) {
        try {
          const getStripeRefund = httpsCallable<RefundRequest, RefundResponse>(
            FUNCTIONS, 
            'getStripeRefund'
          );

          const response = await getStripeRefund({
            amount: refundData.amount,
            ticketid: refundData.ticketid,
            eventid: refundData.eventid,
            reservationid: refundData.reservationid,
            pi: refundData.pi,
            userid: refundData.userid,
          });
          console.log(response.data);

          return { data: response.data };
        } catch (error: any) {
          console.error(error.message);
          console.error(error.code);
          return {
            error: {
              status: error?.code || 'UNKNOWN_ERROR',
              data: error?.message || 'An unknown error occurred',
            },
          };
        }
      },
      // Invalidate relevant cache after refund
      invalidatesTags: ['Tickets', 'Reservations'],
    }),
    sendConfirmationEmail: builder.mutation<void, { mode: string, reservationid: string }>({
      async queryFn({ mode, reservationid }) {
        try {
          const createMailTask = httpsCallable(FUNCTIONS, 'createMailTask');
          await createMailTask({ mode, reservationid });
          return { data: undefined };
        } catch (error: any) {
          return { error: error.message };
        }
      }
    }),
    
  }),
});

export const { useGetAttendeesQuery, 
  useGetConfirmedTicketIdsQuery,
  useSendConfirmationEmailMutation,
  useUpdateReservationUserInfoMutation,
  useRefundTicketMutation 
} = ReservationsApi;

